const Tracker = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      {...props}
    >
      <rect
        width="13.3698"
        height="13.3698"
        transform="translate(0.816406 0.815063)"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.20545 2.57367V3.08964H8.96101V2.57367H6.20545ZM5.8712 1.40381C5.42124 1.40381 5.05435 1.75946 5.03629 2.20498H4.19466C3.27167 2.20498 2.52344 2.95321 2.52344 3.87621V11.9251C2.52344 12.8481 3.27167 13.5963 4.19466 13.5963H10.8038C11.7268 13.5963 12.475 12.8481 12.475 11.9251V3.87621C12.475 2.95321 11.7268 2.20498 10.8038 2.20498H10.1302C10.1121 1.75946 9.74521 1.40381 9.29525 1.40381H5.8712ZM10.1309 3.42388V3.37484H10.8038C11.0807 3.37484 11.3052 3.59931 11.3052 3.87621V11.9251C11.3052 12.202 11.0807 12.4265 10.8038 12.4265H4.19466C3.91776 12.4265 3.69329 12.202 3.69329 11.9251V3.87621C3.69329 3.59931 3.91776 3.37484 4.19466 3.37484H5.03559V3.42388C5.03559 3.88538 5.40971 4.2595 5.8712 4.2595H9.29525C9.75675 4.2595 10.1309 3.88538 10.1309 3.42388ZM10.427 6.57439C10.6539 6.34448 10.6515 5.97413 10.4215 5.7472C10.1916 5.52027 9.82128 5.52269 9.59435 5.75261L7.20413 8.17428L5.63936 6.75122C5.40037 6.53387 5.03043 6.55141 4.81308 6.79041C4.59573 7.0294 4.61327 7.39934 4.85227 7.61669L6.71375 9.30961C7.01219 9.58102 7.47161 9.56863 7.75499 9.28153L10.427 6.57439Z"
        fill="#333333"
      />
    </svg>
  )
}

export default Tracker
